// ASTRES

.row--astres {
  @include inline-block-list();
  padding: 0 0 3em 0;
}

.astre__block {
  @include transition(all .3s linear);
  @include inline-block-item();
  background: #fff;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
  min-height: 20.45em;
  vertical-align: top;
  width: 33.3333333%;
  z-index: 1;
  @media (max-width: $screen-desktop) {
    min-height: 15em;
  }
  @media (max-width: $screen-xs-max) {
    display: block;
     min-height: 5em;
    width: 100%;
  }
  a {
    @include transition(all .3s linear);
    background: #fff;
    display: block;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    &:hover {
      background: $bg-highlight;
    }
  }
  &:nth-child(odd) {
    a {
      background: $bg-home;
      &:hover {
        background: $bg-highlight;
      }
    }
  }
  &:first-child {
    background: $bg-home;
    @media (max-width: $screen-xs-max) {
      background: none;
      a {
         background: none;
      }
    }
      a:hover {
        background: $bg-home;
        @media (max-width: $screen-xs-max) {
          background: none;
        }
      }
  }
  h1 {
    display: block;
    font-size: em(38px);
    font-weight: bold;
    line-height: 1;
    padding: 0 0 0 1em;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-66%);
    transform: translateY(-66%);
    @media (max-width: $screen-xs-max) {
      font-size: em(23px);
      padding: 1em;
      position: static;
      top: auto;
      -webkit-transform: none;
      transform: none;
    }
    strong {
      font-size: em(45px,38px);
      @media (max-width: $screen-xs-max) {
        font-size: 1em;
      }
    }
  }
}
