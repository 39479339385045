// FOOTER

.footer {
	background: #fff;
	display: block;
	margin: 0;
	padding: 0;
	position: fixed;
	left: 0;
	bottom:0;
	width: 100%;
	z-index: 5;
    border-left-style: solid;
    border-left-width: 16px;
    .homepage & {
    	background: $bg-home;
     	border-left-color: $border-home;
    }
    .astre & {
      border-left-color: $border-single;
    }
    .astres & {
      border-left-color: $border-category;
    }
	p {
		display: block;
		float: left;
		margin: 0;
		padding: 0 10px 10px 10px;
		@media (max-width: $screen-xs-max) {
			font-size: em(12px);
		}
	}
	.footer-about {
		float: right;
		padding: 0 10px;
	}
	#about-link {
		cursor: pointer;
	}
}

// ABOUT

#about {
	background: $bg-home;
	border-left: 16px solid $border-category;
	display: none;
	font: normal em(12px) 'Kavivanar', Arial, Verdana, sans-serif;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	h2 {
		display: block;
		font: bold em(22px,12px) 'Roboto Slab', Arial, Verdana, sans-serif;
		margin: 0;
		padding: 0 0 10px 0;
	}
	h3 {
		display: block;
		font-size: 1em;
		margin: 0;
		padding: 0 0 10px 0;
		text-transform: uppercase;
	}
	a {
		text-decoration: underline;
	}
}

.container--about {
  width: 66%;
  @media (max-width: $screen-xs-max) {
  	padding: 30px 0;
    width: 100%;
  }
}

.row--about {
  display: table;
  table-layout: fixed;
  width: 100%;
  > div {
    display: table-cell;
    float: none;
    vertical-align: top;
  }
  dl, dt, dd, p {
    clear: both;
    display: block;
    margin: 0;
    padding: 0;
  }
  dd, p {
    padding: 0 0 10px 0;
  }
}

.col-about-illustration {
  background:url("/images/about/capitaine-haddock.svg") left center no-repeat;
  background-size: contain;
}

.about__inner {
    display: flex;
    min-height: 100vh;
	@media (max-width: $screen-xs-max) {
		display: block;
		padding: 0 0 40px 0;
	}
}

.container {
  .about__inner & {
    margin: auto;
  }
}

// CLOSE

.bt_close {
	cursor: pointer;
	display: block;
	font: bold 4em Arial, Verdana, sans-serif;
	margin: 0;
	padding: 10px 0;
	@media (max-width: $screen-xs-max) {
		padding: 0;
		position: fixed;
		right: 10px;
		bottom: 0;
	}
}