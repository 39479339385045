@charset "UTF-8";
@import "_variables";
@import "_bootstrap";

/* GLOBAL */

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html { height:100%; font-size: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }
html, button, input, select, textarea { font-family: 'Roboto Slab', Arial, Verdana, sans-serif; font-weight: 400; color:$default-color; }
body {
    background: $bg-default;
    border-left-style: solid;
    border-left-width: 16px;
    min-height: 100%;
    margin: 0;
    padding: 0 0 40px 0;
    font-size: 1em;
    &.homepage {
      background: $bg-home;
      border-left-color: $border-home;
    }
    &.astre {
      border-left-color: $border-single;
    }
    &.astres {
      border-left-color: $border-category;
    }
}

.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }

hr { border:0; border-top-width:1px; border-color: $default-color; border-style:solid; clear:both; height:0; display:block; margin:0; }

a { color: $default-link-color; text-decoration:none; -ms-touch-action: manipulation; touch-action: manipulation; }
a:active, a:visited, a:hover, a:link { color: $default-link-color; }
a:focus, input:focus, textarea:focus, button:focus { outline: thin dotted; }

::-moz-selection {
    background: $bg-default;
    color: $default-color;
    text-shadow: none;
}

::selection {
    background: $bg-default;
    color: $default-color;
    text-shadow: none;
}

.disable-hover, .disable-hover * {
  pointer-events: none !important;
}

.touch-device input[type="text"]:focus, .touch-device input[type="email"]:focus, .touch-device input[type="url"]:focus, .touch-device input[type="password"], .touch-device textarea:focus, .touch-device select:focus {
  font-size: 16px !important;
}

.touch-device input, .touch-device textarea {
	box-shadow: none;
	-webkit-appearance: none;
	border-radius: 0;
}

.touch-device input[type="checkbox"] {
  -webkit-appearance: checkbox;
}

.touch-device input[type="radio"] {
   -webkit-appearance: radio;
}

.left { float:left; }
.right { float:right; }
.aligncenter { text-align:center; }
.txtalignleft { text-align:left; }
.txtalignright { text-align:right; }
.alignleft { float:left; margin:0 16px 10px 0; }
.alignright { float:right; margin:0 0 10px 16px; }
img.alignleft, img.alignright, img.aligncenter { display:block; }
img.aligncenter { margin-left:auto; margin-right:auto; margin-bottom:10px; }
b, strong { font-weight: bold; }
ul, ol { margin: 0 0 1em 0; padding: 0 0 0 40px; }
img { border: 0; vertical-align: middle; }

form { margin: 0; }
fieldset { border: 0; margin: 0; padding: 0; }
label { cursor: pointer; }
button, input, select, textarea { font-size: 100%; margin: 0; vertical-align: baseline; }
button { background:none; border:none; margin:0; padding:0; outline:none; }
button:focus, input:focus, textarea:focus { outline:none; }
.oldie select, .oldie textarea { vertical-align: middle; }
button, input { line-height: normal; }
button, input[type="button"], input[type="reset"], input[type="submit"] { cursor: pointer; -webkit-appearance: button; }
.oldie button, .oldie input { overflow: visible; vertical-align: middle; }
textarea { overflow: auto; vertical-align: top; resize: vertical; }
.img-responsive { display: block; max-width: 100%; width: 100%; height: auto; -ms-interpolation-mode: bicubic; }
iframe { border:none; }
.unstyled { list-style-type:none; margin:0; padding:0; }
.clearfix { zoom:1; }
.clearfix:before, .clearfix:after { content:""; display:table; }
.clearfix:after { clear:both; }
.media { overflow: hidden; zoom:1; }

// WRAPPER

.wrapper {
  .homepage & {
    display: flex;
    min-height: calc(100vh - 40px);
    @media (max-width: 767px), (max-height: 600px) {
      display: block;
      min-height: 100%;
    }
  }
}

.container {
  .homepage & {
    margin: auto;
  }
}
