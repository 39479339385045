// VARIABLES
$base-font-size: 16px;
$default-color: #000;
$default-link-color: #000;

$bg-default: #fff;
$bg-home: #ffde40;
$bg-highlight: #00a6de;
$border-single: #3ca000;
$border-home: #ec1c24;
$border-category: #00a6de;
                                