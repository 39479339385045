// OPACITY
@mixin opacity($value) {
  opacity: $value / 100;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=#{$value})";
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$value});
}

// BOX-SIZING
@mixin border-box-sizing() {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

// IMAGE REPLACEMENT
@mixin image-replacement($width, $height) {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  height: #{$height}px;
  width: #{$width}px;
}

// TEXT WRAP
@mixin text-wrap() {
  white-space: pre;
  white-space: pre-wrap;
  white-space: pre-line;
  white-space: -moz-pre-wrap;
  white-space: -hp-pre-wrap;
  word-wrap: break-word;
}

// INLINE BLOCK LIST
@mixin inline-block-list() {
  letter-spacing: -0.31em;
  text-rendering: optimizespeed;  
}

// INLINE BLOCK ITEM
@mixin inline-block-item() {
  letter-spacing: normal;
  word-spacing: normal;
  text-rendering: auto;
}

// COLUMNS
@mixin columns($column-count, $column-gap) {
  -webkit-column-count: $column-count;
  -moz-column-count: $column-count;
  column-count: $column-count;
  -webkit-column-gap: $column-gap;
  -moz-column-gap: $column-gap;
  column-gap: $column-gap; 
}

// CSS TRANSITION
@mixin transition($transition) {
  -webkit-transition:$transition;
  -moz-transition:$transition;
  -o-transition:$transition;
  -ms-transition:$transition;
  transition:$transition;
  -webkit-backface-visibility: hidden;
}

// CSS ANIMATION
@mixin animation($transition) {
  -webkit-animation:$transition;
  -moz-animation:$transition;
  -o-animation:$transition;
  -ms-animation:$transition;
  animation:$transition;
  -webkit-transform: translateZ(0);    
}

// CSS TRANSFORM
@mixin transform($transforms) {
     -moz-transform: $transforms;
       -o-transform: $transforms;
      -ms-transform: $transforms;
  -webkit-transform: $transforms;
          transform: $transforms;
}

// CSS TRANSLATE
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
      -ms-transform: translate($x, $y);
       -o-transform: translate($x, $y);
          transform: translate($x, $y);
}
// CSS TRANSITION TRANSFORM
@mixin transition-transform($transition...) {
  -webkit-transition: -webkit-transform $transition;
     -moz-transition: -moz-transform $transition;
       -o-transition: -o-transform $transition;
          transition: transform $transition;
}

// EM CONVERSION
@function em($px, $base: $base-font-size) {
    @return ($px / $base) * 1em;
}

// PLACEHOLDER
@mixin input-placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

// INLINE BLOCK LIST
@mixin inline-block-list() {
  letter-spacing: -0.31em;
  text-rendering: optimizespeed;  
}

// INLINE BLOCK ITEM
@mixin inline-block-item() {
  letter-spacing: normal;
  word-spacing: normal;
  text-rendering: auto;
}

// HEX TO RGBA
@mixin rgba-background($hexcolor, $opacity) {
  background: $hexcolor;
  background: rgba($hexcolor, $opacity); 
}

// RETINA
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    background-image: image-url($image);
    background-size: $width $height;
  }
}

// SVG WITH PNG FALLBACKS
@mixin svg-background-image($name, $size:false) {
    background-image: image-url("#{$name}.svg");
    @if($size){
        background-size: $size;
    }
    .no-svg &{
        background-image: image-url("#{$name}.png");
    }
}
