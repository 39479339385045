// ASTRE

#astre-container {
  padding: 25px 9em 50px 9em;
  text-align: center;
  @media (max-width: $screen-tablet) {
    padding: 20px 3em;
  }
  @media (max-width: $screen-xs-max) {
    padding: 20px 10px;
    text-align: left;
  }
}

// HEADER

.astre__header {
  h1 {
    display: block;
    font-size: em(46px);
    line-height: 1;
    font-weight: bold;
    margin: 0;
    padding: 0 0 .5em 0; 
    @media (max-width: $screen-xs-max) {
      font-size: em(26px);
    }
  }
  img {
    display: block;
    height: auto;
    margin: 0 auto;
    width: 50%;
    max-width: 50%;
    @media (max-width: $screen-xs-max) {
      width: 100%;
      max-width: 100%;
    }
  }
  p {
    display: block;
    font: normal em(18px) 'Kavivanar', Arial, Verdana, sans-serif;
  }
  em {
    font-size: em(32px,18px);
    @media (max-width: $screen-xs-max) {
      display: block;
    }
  }
}

// CONTENT

.astre__content {
  display: block;
  font: normal em(14px) 'Kavivanar', Arial, Verdana, sans-serif;
  margin: 0;
  padding: 0 0 1em 0;
  text-align: center;
  @media (max-width: $screen-xs-max) {
    text-align: left;
  }
}

// SHARE

.share {
  font: normal em(14px) 'Kavivanar', Arial, Verdana, sans-serif;
  padding: 0 0 1.5em 0;
  text-align: center;
}

.share__item {
  display: inline-block;
  margin: 0;
  padding: 0 .75em;
  vertical-align: top;
  a {
      @include transition(all .3s linear);
      svg:hover #bt-facebook, svg:hover #bt-twitter, svg:hover #bt-whatsapp {
        fill: $bg-highlight;
      }
  }
}

.no-touch-device .share__item--whatsapp {
  display: none;
}

// ACTIONS

.astre__group {
  padding: 0 0 1em 0; 
  text-align: center;
}

.astre__action__item {
  display: inline-block;
  margin: 0;
  padding: 1em 0 0 .5em;
  vertical-align: top; 
  a {
    @include transition(all .3s linear);
    border: 1px solid #000;
    border-radius: 25px;
    color: #000;
    font-size: em(14px);
    font-weight: bold;
    line-height: 1.1;
    display: block;
    margin: 0;
    padding: 10px 20px;
    text-transform: uppercase;
    &:hover {
      border-color: $bg-highlight;
      color: $bg-highlight;
    }
  }
}

// TAGLINE

.astre__footer {
  padding: 0 0 60px 0;
  position: relative;
  text-align: left;
  img {
    margin: 0 10px 30px 0;
  }
}

.astre__footer__inner {
  font-size: em(12px);
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  min-width: 50%;
  @media (max-width: $screen-xs-max) {
   left: 0;
   -webkit-transform: none;  
   transform: none; 
   width: 100%;
  }
}