// HOME

.row--homepage {
  display: table;
  table-layout: fixed;
  width: 100%;
  > div {
    display: table-cell;
    float: none;
    vertical-align: top;
    @media (max-width: $screen-xs-max) {
      text-align: right;
    }
  }
}

.col-home-illustration {
  background:url("/images/home/tintin.svg") center top no-repeat;
  background-size: contain;
}

.logo-mobile {
  padding: 1em 0 0 0;
}

.homepage__inner {
  padding: 0 0 0 12em;
  @media (max-width: $screen-desktop) {
    padding: 0 0 0 9em;
  }
  @media (max-width: $screen-xs-max) {
    padding: 0;
  }
}

h1.entry-title {
  display: block;
  font-size: em(46px);
  line-height: 1;
  font-weight: bold;
  margin: 0;
  padding: .5em 0;
  @media (max-width: $screen-lg) {
    font-size: em(22px);
  }
  @media (max-width: $screen-xs-max) {
    font-size: em(22px);
  }
  strong {
    font-size: em(55px,46px);
    @media (max-width: $screen-lg) {
      font-size: em(24px,22px);
    }
    @media (max-width: $screen-xs-max) {
      font-size: em(26px,22px);
    }
  }
}

.exposition__date {
  display: block;
  font-size: em(26px);
  margin: 0;
  padding: .5em 0 0 0;
  @media (max-width: $screen-lg) {
    font-size: em(12px);
  }
}

.birthday__form {
  display: block;
  margin: 0;
  padding: 0;
  width: 230px;
  @media (max-width: $screen-xs-max) {
    width: 100%;
  }
  label {
    display: block;
    font-size: em(16px);
    font-weight: bold;
    margin: 0;
    padding: 0 0 5px 0;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: $screen-lg) {
      font-size: em(14px);
    }
    @media (max-width: $screen-xs-max) {
      font-size: em(13px);
       text-align: right; 
    }
  }
  input {
    @include input-placeholder($bg-highlight);
    background: #fff;
    border: 1px solid #000;
    border-radius: 4px 0 0 4px;
    color: $bg-highlight;
    display: block;
    float: left;
    font-size: em(24px);
    height: 40px;
    line-height: 40px;
    margin: 0;
    padding: 0 .5em;
    text-align: left;
    width: 170px;
    @media (max-width: $screen-xs-max) {
      font-size: em(14px);
      width: 72.5%;
    }
  }
  button {
    background: #000;
    border: none;
    border-radius: 0 4px 4px 0;
    color: #fff;
    display: block;
    float: left;
    height: 40px;
    line-height: 40px;
    margin: 0;
    padding: 0 10px;
    text-align: center;
    text-transform: uppercase;
  }
}

.form__inner {
    @media (max-width: $screen-xs-max) {
      float: right;
    }  
}

.form__footer {
  display: block;
  font: normal em(18px) 'Kavivanar', Arial, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  text-align: center;
  @media (max-width: $screen-lg) {
    font-size: em(12px);
  }
  @media (max-width: $screen-xs-max) {
    font-size: em(12px);
    text-align: right;
  }
}